.quest-image {
	background-repeat: no-repeat;
	background-size: contain;
	background-position: center;
	width: 100%;
	height: 100%;
	border-radius: 10px;
	overflow: hidden;
}

.quest__image-4 .quest-image {
	background-size: contain;
	background-color: rgba(0, 0, 0, 0.05);
}

.quest__image-4 .quest-image__overlay {
	border: 3px solid rgba(63, 138, 224, 0.75);
	width: calc(100% - 6px);
	height: calc(100% - 6px);
	border-radius: 10px;
}
