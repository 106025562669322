.task-about {
	position: relative;
	padding-top: 0 !important;
	padding-bottom: 0 !important;
}

.task-about__wrap {
	position: relative;
	padding-top: 8px;
	padding-bottom: 8px;
}

.task-about__wrap:after {
	content: '';
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: var(--separator_common);
	height: 1px;
	transform: scaleY(0.5);
}

.task-about__title {
	font-size: 24px;
	font-weight: 600;
	line-height: 1.13;
	letter-spacing: 0.38px;
	color: var(--text_muted);
}

.task-about__text {
	margin-top: 6px;
	font-size: 15px;
	line-height: 1.35;
	letter-spacing: -0.24px;
	color: var(--text_primary);
}

.task-about__pay {
	margin-top: 16.5px;
	margin-bottom: 14px;
	font-size: 15px;
	line-height: 1.33;
	letter-spacing: -0.24px;
	color: var(--text_secondary);
	display: flex;
}

.task-about__pay span {
	margin-bottom: 1px;
	margin-right: 4px;
	font-size: 20px;
	font-weight: 600;
	line-height: 1;
	letter-spacing: -0.41px;
	color: var(--dynamic_green);
}

.task-about__pay-normal, .task-about__pay-maximum {
	display: flex;
	flex-direction: column;
	margin-right: 16px;
}

.task-about__pay-maximum span {
	color: var(--text_muted);
}
