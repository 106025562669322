.rating {
	position: relative;
	padding-top: 0 !important;
	padding-bottom: 19px !important;
}

.rating:after {
	content: '';
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: var(--separator_common);
	height: 1px;
	transform: scaleY(0.5);
}

.rating__title {
	height: 40px;
	display: flex;
	align-items: center;
	font-size: 13px;
	font-weight: 600;
	text-transform: uppercase;
	line-height: 1.23;
	letter-spacing: -0.08px;
	color: var(--text_secondary);
}

.rating__list {
	display: flex;
	flex-direction: column;
}

.rating__item {
	position: relative;
	display: flex;
	align-items: center;
}

.rating__item .Cell {
	flex-grow: 1;
}

.rating__position {
	min-width: 32px;
	text-align: center;
	font-size: 16px;
	font-weight: 500;
	letter-spacing: -0.1px;
	color: var(--text_secondary);
}

.rating__separator-android {
	position: relative;
	margin: 4px 0;
}

.rating__separator-android .rating__separator-content {
	position: absolute;
	left: 48px;
	right: 0;
	background-color: var(--separator_common) !important;
	height: 1px;
	transform: scaleY(0.5);
	transform-origin: center;
}

.rating__separator-ios {
	position: relative;
	margin: 4px 0;
}

.rating__separator-ios .rating__separator-content {
	position: absolute;
	left: 44px;
	right: 0;
	background-color: var(--separator_common) !important;
	height: 1px;
	transform: scaleY(0.5);
	transform-origin: center;
}
