.task-statistic {
  position: relative;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.task-statistic__wrap {
  position: relative;
  padding-bottom: 16px;
}

.task-statistic__wrap:after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--separator_common);
  height: 1px;
  transform: scaleY(0.5);
}

.task-statistic__title {
  height: 40px;
  display: flex;
  align-items: center;
  font-size: 13px;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 1.23;
  letter-spacing: -0.08px;
  color: var(--text_secondary);
}

.task-statistic__list {
  display: flex;
  flex-direction: column;
  margin-top: 4px;
  margin-bottom: 4px;
}

.task-statistic__item {
  display: flex;
  align-items: center;
}

.task-statistic__item span {
  margin-left: 10px;
  font-size: 15px;
  letter-spacing: -0.24px;
  color: var(--text_primary);
}

.task-statistic__item + .task-statistic__item {
  margin-top: 16px;
}
