.quest-group {
	padding-top: 0 !important;
	padding-bottom: 0 !important;
}

.quest-group__title {
	padding-top: 15px !important;
	padding-bottom: 14px !important;
	font-size: 13px;
	font-weight: 600;
	line-height: 1.23;
	letter-spacing: -0.08px;
	text-transform: uppercase;
	color: var(--text_secondary);
}

.quest-group__content {
	color: var(--text_primary);
}

.quest-group .Search {
	background: transparent !important;
	box-shadow: none;
	padding: 0 6px 12px 6px !important;
	height: auto !important;
}

.quest-group .Search__in {
	overflow: hidden;
}

.quest-group .Search--ios {
	padding-left: 12px !important;
	padding-right: 12px !important;
}

.quest-group .Search--ios .Search__control {
	background: var(--search_bar_background);
}

.quest-group .Search--ios .Search__after-width {
	opacity: 0;
}

.quest-group .Search--ios .Search__after {
	background: transparent !important;
}

.quest-group .Search--ios .Search__after-in {
	padding-right: 12px !important;
}

.quest-group .Search--ios .Search__after:before, .quest-group .Search--ios .Search__after:after {
	display: none;
}

.quest-group .Search--android {
	padding-left: 16px !important;
	padding-right: 16px !important;
}

.quest-group .Search--android .Search__container {
	background: var(--search_bar_background);
}

.quest-group .Search--android .Search__input {
	color: var(--text_primary);
}

.radio-check .Checkbox {
	padding: 0 !important;
	display: inline-block;
	border-radius: 10px !important;
	margin-right: 8px !important;
	margin-bottom: 8px !important;
}

.radio-check .Checkbox__icon {
	display: none;
}

.radio-check .Checkbox__container {
	border-radius: 10px;
	border: solid 1px var(--background_page) !important;
	padding: 6px 12px;
	color: #818c99;
}

.radio-check .Checkbox .Checkbox__input:checked ~ .Checkbox__container {
	background-color: var(--accent) !important;
	border: solid 1px var(--accent) !important;
}

.radio-check .Checkbox .Checkbox__input:checked ~ .Checkbox__container .Checkbox__content {
	color: #fff !important;
}
