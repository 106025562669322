.quest-header {
	background: var(--background_content);
}

.quest-header__progress {
	display: flex;
	flex-direction: column;
	padding-top: 30px !important;
	padding-bottom: 7px !important;
}

.quest-header__progress__label {
	font-size: 15px;
	font-weight: 600;
	letter-spacing: -0.24px;
	color: var(--text_primary);
	margin-bottom: 8px;
}

.quest-header__progress__line {
	position: relative;
	width: 100%;
	height: 4px;
	border-radius: 2px;
	background: var(--placeholder_icon_background);
	overflow: hidden;
}

.quest-header__progress__line span {
	height: 100%;
	display: block;
	border-radius: 2px;
	background: var(--accent);
}

.quest-header__reward {
	display: flex;
	align-items: center;
	padding-top: 9px !important;
	padding-bottom: 16px !important;
}

.quest-header__reward__item {
	display: flex;
	align-items: center;
}

.quest-header__reward__item span {
	font-size: 15px;
	line-height: normal;
	letter-spacing: -0.24px;
	color: var(--text_primary);
	margin-left: 8px;
}

.quest-header__reward__item + .quest-header__reward__item {
	margin-left: 18px;
}

.quest-header__reward__item:last-child {
	margin-left: auto;
}
