.quest {
	padding-top: 16px;
	padding-bottom: 12px;
}

.quest__title {
	font-size: 17px;
	font-weight: 600;
	line-height: 1.29;
	letter-spacing: 0.32px;
	color: var(--text_muted);
	margin-bottom: 12px;
}

.quest__box {
	padding-bottom: 100%;
	position: relative;
	margin-top: 12px;
}

.quest__box-wrap {
	position: absolute;
	width: 100%;
	height: 100%;
}

@media (min-width: 700px) {
	.quest__box--choose-option {
		height: 450px;
		padding-bottom: 0;
	}
}

.quest__image-4 {
	display: flex;
	flex-wrap: wrap;
}

.quest__image-4 .Radio {
	position: relative;
	padding: 0;
	width: calc(50% - 4px);
	height: calc(50% - 4px);
	margin: 2px;
	border-radius: 10px;
}

.quest__image-4 .Radio__container {
	width: 100%;
	height: 100%;
}

.quest__image-4 .Radio__icon {
	position: absolute;
	top: 11px;
	right: 11px;
	margin: 0 !important;
	z-index: 2;
	border-color: var(--button_commerce_foreground) !important;
}

.quest__image-4 .Radio__content {
	position: absolute;
	width: 100%;
	height: 100%;
}

.quest__image-4 .Radio__input:checked ~ .Radio__container .Radio__icon {
	border-color: var(--button_commerce_foreground);
}

.quest__image-4 .Radio__input:checked ~ .Radio__container .Radio__icon:after {
	background-color: var(--button_commerce_foreground) !important;
}
