.rating-item {
	position: relative;
	display: flex;
	align-items: center;
}

.rating-item .Cell {
	flex-grow: 1;
}

.rating-item__position {
	min-width: 32px;
	text-align: center;
	font-size: 16px;
	font-weight: 500;
	letter-spacing: -0.1px;
	color: var(--text_secondary);
}
