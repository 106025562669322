.quest-actions {
	background: var(--background_content);
}

.quest-actions:after {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	background-color: var(--separator_common);
	height: 1px;
	transform: scaleY(0.5);
}

.quest-actions__buttons {
	display: flex;
}

.quest-actions__buttons .Button + .Button {
	margin-left: 8px;
}

.quest-actions__buttons-column .Button + .Button {
	margin-top: 8px;
}

.quest-actions__square-button {
	width: auto !important;
	padding: 0 !important;
}
