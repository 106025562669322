.quest-finish {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 120px);
}

.quest-finish__title {
  margin-top: 10px;
  font-size: 20px;
  font-weight: 600;
  line-height: 1.2;
  letter-spacing: 0.38px;
  text-align: center;
  color: var(--text_primary);
}

.quest-finish__stats {
  margin-top: 40px;
  display: flex;
  justify-content: space-around;
  width: 100%;
}

.quest-finish__stats-title {
  font-size: 13px;
  font-weight: 500;
  letter-spacing: -0.08px;
  text-align: center;
  color: var(--tabbar_inactive_icon);
  text-transform: uppercase;
  margin-bottom: 4px;
}

.quest-finish__stats-text {
  font-size: 20px;
  font-weight: 600;
  line-height: 1.2;
  letter-spacing: 0.38px;
  text-align: center;
  color: var(--text_primary);
}

.quest-finish__item {
  display: flex;
  flex-direction: column;
}
